import React from 'react'
import "./InteriorDesign.css"
import { useNavigate } from 'react-router-dom'

function ImgCard({
    title="",
    content="",
    img="",
    width="50",
    anchor="/"
})
{
    const navigate = useNavigate()
    return(
        <div 
        className='img-container w-100 d-flex flex-column align-items-start justify-content-end p-xl-5 p-4'
        style={{backgroundImage:`url(${img})`}}
        onClick={()=>{navigate(anchor)}}
    >
        <h3 className='body-heading title color-light' style={{textTransform:"upper-case"}}>{title}</h3>
        <p className={`body-paragraph content color-light w-${width}`}>{content}</p>
    </div>
    )
}

function InteriorDesign() {
  return (
    <div className='InteriorDesign-container standard-padding-space standard-margin-space'>
        <div className='container'>
            <div className='text-sec mb-5 pb-4 text-center text-lg-start'>

                <div className='row'>
                    <div className='heading-sec col-lg-6'>
                        <h3 className='body-heading color-dark'>A BROAD SET OF INTERIOR DESIGN</h3>
                    </div>
                    <div className='heading-sec col-lg-6'>
                        <p className='body-paragraph color-dark'>Whether you prefer a contemporary feel or a luxury look, our customized designs are tailored to enhance the appeal of every room. We aim to develop welcoming and stylish spaces that accommodate different tastes.</p>
                    </div>

                </div>
                {/* <button className='genral-btn-light text-start'>Learn More</button>    */}

            </div>
            <div className='img-sec my-4'>
                <div className='d-flex flex-column flex-lg-row align-items-center justify-content-between gap-xl-5 gap-4'>
                    <div className='w-100 w-lg-50'>
                    <ImgCard
                        title="Home Staging - Homeowners"
                        content='When working with homeowners, we are living spaces to accentuate their best features, making them more attractive and ready for the market. Our staging services help transform your home into a buyer’s dream.'
                        img='/images/creatives/id-card-bg-1.jpg'
                        width='100'
                        anchor="/staging-home-owner"
                    />
                    </div>
                    <div className='w-100 w-lg-50'>
                        <ImgCard
                            title="Home Staging - Realtors"
                            content='We collaborate with real estate agents to strategically stage homes, drawing in potential buyers and ensuring properties sell quickly. Our expertise guarantees that each home is showcased to sell.'
                            img='/images/creatives/id-card-bg-2.jpg'
                            width='100'
                            anchor="/staging-realtors"
                        />
                    </div>

                </div>
                    <div className='mt-xl-5 mt-4'>
                        <ImgCard
                            title="Home Staging - Builders"
                            content='We collaborate with builders to design model homes or new builds that reflect the buyer’s lifestyle dreams. Our designs help investors see the long-term benefits and help first-time home buyers envision themselves in these new homes.'
                            img='/images/creatives/id-card-bg-3.jpg'
                            width='50'
                            anchor="/staging-builders"
                        />
                    </div>
            </div>             
        </div>

    </div>
  )
}

export default InteriorDesign