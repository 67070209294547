import React from 'react'
import "./ScrollingTextContent.css"
import { useNavigate } from 'react-router-dom'

function ScrollingTextContent({
    bgColor,
    shiftRow=false,
    heading="",
    nextLineSpanHeading="",
    subHeading="",
    content="",
    img,
    btn=false,
    btnText="Read More",
    btnRef="/",
    btnYellow=false,
    fontDark=true,
    subHeadingLine=true


}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph mb-3'>{paragraph}</p>);
    const navigate = useNavigate()
  return (
    <div className='ScrollingTextContent-container standard-padding-space mb-4' style={{backgroundColor: bgColor}}>
        <div className='container py-5 my-3'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-center gy-4`}>
                <div className='col-md-6 h-100 my-auto px-4'>
                    <img src={ img} className='img-fluid' alt='icon'/>
                </div>
                <div className='col-md-6'>
                    <div className='text-content d-flex flex-column align-items-start justify-content-center gap-lg-2 h-100 px-2 px-md-0'>
                    {subHeading && <p className={`sub-heading mb-0 justify-content-lg-start justify-content-center d-flex aling-items-center gap-lg-4 gap-3 ${fontDark ? "color-dark":"color-light"}`}>{subHeadingLine &&<div style={{height:"0",width:"50px",borderBottom:`1px solid ${fontDark ? "black":"white"}`,margin:"auto 0" }}></div>}{subHeading}</p>}
                        <h3 className='heading body-heading'>{ heading}<br></br>{nextLineSpanHeading}</h3>
                        <div className='scroll-content'>
                           
                           {paragraphs}

                        </div>
                        {/* <p className='content body-paragraph'>{ paragraphs}</p> */}
                        {btn && <button className={`genral-btn ${btnYellow && "btn-y"} mt-2`} onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ScrollingTextContent
