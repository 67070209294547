import React from 'react'
import "./OccupiedStagging.css"
import { useNavigate } from 'react-router-dom';


function CardOS({
    title="",
    content="",
    lightTheme
}){
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className={`body-paragraph my-1 mb-3 ${lightTheme?"color-dark" :"color-light"}`}>{paragraph}</p>);
    return(
        <div className='card-box p-5'
            style={lightTheme?{border:"1px solid black"}:{border:"1px solid white"}}
        >
            <div className='d-flex flex-column gap-3 text-center justify-content-center align-items-center h-100'>
                <h5 className={`sub-heading ${lightTheme?"color-dark" :"color-light"} mb-1`}>{title}</h5>
                <p className={`body-paragraph ${lightTheme?"color-dark" :"color-light"} card-para`}>{paragraphs}</p>
            </div>
        </div>
    )
}

function OccupiedStagging({
    heading="",
    content="",
    card1={
      title:"",
      content:""
    },
    card2={
      title:"",
      content:""
    },
    card3={
      title:"",
      content:""
    },
    lightTheme=false
    
}) {
    const navigate = useNavigate()
  return (
    <div 
        className='OccupiedStagging-container '
        style={lightTheme ? {background:"white"}:{background:"black"}}
    
    >
        <div className={`${!lightTheme && "left-gradient-upper"} standard-padding-space`}>
        <div className='container my-4'>

            <div className='text-content'>
                <div className='row gy-3 align-items-center'>
                    <div className='col-lg-6'>
                        <h3 className={`body-heading ${lightTheme?"color-dark" :"color-light"}`} >{heading}</h3>
                        <p className={`body-paragraph  my-4 ${lightTheme?"color-dark" :"color-light"}`}>{content}</p>
                    </div>
                    <div className='col-lg-6 text-center text-lg-end my-3 my-lg-0'>
                        <button className={`${lightTheme?"genral-btn-light":"genral-btn"} `} onClick={()=>{navigate("/get-a-quote")}}>Get A QUOTE</button>
                    </div>
                </div>
            </div>

            <div className='card-container'>
                <div className='row gy-4 mt-4'>
                    <div className='col-lg-4'>
                        <CardOS
                            title={card1.title}
                            content={card1.content}
                            lightTheme={lightTheme}
                        />
                    </div>
                    <div className='col-lg-4'>
                        <CardOS
                             title={card2.title}
                             content={card2.content}
                             lightTheme={lightTheme}
                        />
                    </div>
                    <div className='col-lg-4'>
                        <CardOS
                             title={card3.title}
                             content={card3.content}
                             lightTheme={lightTheme}
                        />
                    </div>

                </div>

            </div>
        </div>
        </div>
    </div>
  )
}

export default OccupiedStagging