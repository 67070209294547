import React from 'react'
import "./TeamContent.css"

function TeamContent() {
  return (
    <div className='TeamContent-container standard-padding-space'>
        <div className='container my-4 mt-5'>
            <div className='row gy-4 align-items-center flex-column-reverse flex-lg-row'>
                <div className='col-lg-6'>
                    <div className='d-flex align-items-center gap-5'>
                        <div className='img-card d-flex flex-column align-items-start'>
                            <img src='/images/creatives/team-member-1.jpg' alt='member ' className='img-fluid'/>
                            <div className='text-center text-lg-start mt-3'>
                                <h5 className='sub-heading title color-dark mb-1 mt-1'>LOUAY NOUNEH</h5>
                                <p className='body-paragraph color-dark mb-0'>Managing Director</p>
                            </div>
                        </div>
                        <div className='img-card d-flex flex-column align-items-start'>
                            <img src='/images/creatives/team-member-2.jpg' alt='member ' className='img-fluid'/>
                            <div className='text-center text-lg-start mt-3'>
                                <h5 className='sub-heading title color-dark mb-1 mt-1'>MIRNA ELIE SARJI</h5>
                                <p className='body-paragraph color-dark mb-0'>Designer</p>
                            </div>
                        </div>

                    </div>

                </div>

                <div className='col-lg-6 px-4'>
                    <div className='text-content'>
                        <h3 className='body-heading color-dark mb-4 text-center text-lg-start' >TEAM MES Décors</h3>
                        <p className='body-paragraph color-dark text-center text-lg-start'>MES Décors is a specialized home and commercial staging company focused on creating visually appealing displays and environments for real estate. We aim to improve the value and appeal of properties, ensuring they look their best for potential buyers. Meet our dedicated team who bring their expertise and passion to every project.</p>

                    </div>
                     
                </div>

            </div>
        </div>

    </div>
  )
}

export default TeamContent