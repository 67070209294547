import React,{useState} from 'react'
import axios from "axios"
import "./ContactPageForm.css"
import {Spinner } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';

function ContactPageForm() {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Message genrated through Contact form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
          navigate("/thank-you");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };

  return (
    <div className='ContactPageForm-container standard-padding-space'>
        <div className='container my-4'>
        <div className='row px-2 px-md-5 gy-4 align-items-center'>
                    <h3 className='body-heading text-center mb-xl-5 mb-4'>Communication is the Key to success throughout the staging process</h3>
                <div className='col-12 d-flex gap-2 align-items-center justify-content-between'>
                    <h3 className='body-heading ' >Get In Touch with Us Today! </h3>
                    <div className='heading-line '></div>
                </div>
               
                <div className='col-lg-6 mt-xl-5 mt-4 contact-inp-form'>
                    <form className='form d-flex flex-column gap-3' onSubmit={formSubmitHandler}>
                    
                        <input 
                         type="text" 
                         value={name}
                         required
                         maxLength="36"
                         onChange={handleNameChange}
                         placeholder="Your Full Name"  
                        className=' input-field' 
                        />
                        <input 
                        type="email" 
                        value={email}
                        required
                        maxLength="66"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Your Email Address" 
                        className=' input-field' />
                    
                        <input 
                         type="tel" 
                         value={phone}
                         maxLength="25"
                         required
                         onChange={handlePhoneChange}
                        placeholder="Phone Number" 
                        className='input-field' />
                        
                        <textarea 
                        className="input-field" 
                        value={message}
                        maxLength="350"
                        required
                        onChange={(e) => setMessage(e.target.value)}
                        rows={4} placeholder="Write Your Message"
                        ></textarea>
                        <button className="genral-btn-light service-from-btn  mt-2" type='submit'>Submit
                        {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                  
                        </button>
                    </form>
                </div>
                <div className='col-lg-6 mt-xl-5 mt-4'>
                    <div className='row gy-4 '>

                        <div className='col-xl-6'>
                            <div className='text-start'>
                                
                                <p className={`body-paragraph color-dark justify-content-lg-start justify-content-center d-flex align-items-center gap-lg-4 gap-3`} style={{textTransform:"uppercase"}}><p className=' mb-0' >Milton, Ontario, Ca</p><div style={{height:"0",width:"30px",borderTop:`1px solid black`,margin:"auto 0" }} className='d-none d-lg-block'></div></p>
                                <a href='mailto:inquiry@mesdecors.ca' className='mt-4' style={{textDecoration:"none"}}>
                                    <h6 className='boody-paragraph color-dark'>inquiry@mesdecors.ca</h6>
                                </a>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className='text-start'>
                            <p className={`body-paragraph color-dark justify-content-lg-start justify-content-center d-flex align-items-center gap-lg-4 gap-3`} style={{textTransform:"uppercase"}}><p className=' mb-0' >CaLL US</p><div style={{height:"0",width:"30px",borderTop:`1px solid black`,margin:"auto 0" }} className='d-none d-lg-block'></div></p>
                                
                                <a href='tel:(437) 998-6650' className='mt-4' style={{textDecoration:"none"}}>
                                    <h6 className='body-paragraph color-dark'>(437) 998-6650</h6>
                                </a>
                            </div>
                        </div>
                        <div className='col-12'>
                            <img className='w-100 img-fluid ' src='/images/creatives/contact-page-form-image.jpg' alt='form'/>

                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>
  )
}

export default ContactPageForm