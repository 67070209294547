import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Sponsor from '../component/Sponsor/Sponsor'
import TextContent from '../component/TextContent/TextContent'
import CardCarousel from "../component/CardCarousel/CardBlog"
import Testimonials from "../component/Testimonials/Testimonials"
import Footer from '../component/Footer/Footer'
import VideoContent from '../component/VideoContent/VideoContent'
import DiscoverServices from "../component/DiscoverServices/DiscoverServices"
import DropDownContent from '../component/DropDownContent/DropDownContent'
import Contactform from '../component/Contact Form/Contactform'
import { Helmet } from 'react-helmet'
import InteriorDesign from '../component/InteriorDesign/InteriorDesign'
import ProjectGlance from '../component/ProjectGlance/ProjectGlance'
import BlogCard from '../component/BlogCard/BlogCard'


const textDropDown = [
    {
      title:"Coaching & Clinicians",
      content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    },
    {
      title:"Rider Development Program",
      content:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
    }
  ]

function Home() {
  return (
    <div >
      <Helmet>
          <title> 
          MES Décors - Professional Home Staging & Consultation Company
          </title>
          <meta 
          name='description' 
          content="Transforming spaces for realtors, homeowners, and builders. MES Décors enhances property appeal, increasing sales and buyer interest with professional  home staging ,interior design and consultation company in Canada." 
          />
          <link rel="canonical" href="https://www.mesdecors.ca/" />

        </Helmet>
        
      
      <HeroBanner 
        subHeading=""
        Heading="HOME STAGING & CONSULTATION COMPANY"
        nextLineSpanHeading=""
        Paragraph=""
        bgImg="/images/creatives/home-hero-bg.jpg"
        btn={true}
        btnRef='/get-a-quote'
        btnText={"GET CONSULTATION NOW"}
        sideLinks={false}
      />

      <TextContent
         subHeading="WHO WE ARE"
         heading= "ABOUT MES Décors"
         content="MES Décors has been providing solutions to property challenges for 15 years. We specialize in decluttering, organizing, and home staging for realtors, homeowners, and builders. Our expert team transforms spaces to attract buyers and increase sales. From comfort to luxury residential to bustling commercial property, we ensure that every space shines."
         btn={true}
         btnText="ABOUT US"
         btnRef='/about'
         shiftRow={true}
         img="/images/creatives/home-text-1.png"
         reviewContent={false}
         btnImg={true}
         fontDark={true}
         />

      <TextContent
         subHeading="Our Portfolio"
         heading= "DESIGN PHILOSOPHY AT "
         nextLineHeading="MES Décors"
         content="Here at MES Décors, our design philosophy evolves around creating appealing and stylish spaces. We believe that every space has a unique story. Our mission is to convey this story through a thoughtful showcasing and staging process, taking into consideration the seller’s motives and the buyer’s emotions as they envision their lifestyle. Our focus is on accentuating the potential of each property to appeal to prospective buyers. Ultimately, our goal is to craft beautiful, functional spaces that leave a lasting impression, and achieve the highest return on investment and maximum equity possible."
         btn={true}
         btnText="OUR PROJECTS"
         btnRef='/portfolio'
         shiftRow={false}
         img="/images/creatives/home-text-2.jpg"
         reviewContent={false}
         btnImg={true}
         fontDark={false}
         />

        <InteriorDesign/>

        <ProjectGlance/>

        <BlogCard/>


      <Testimonials/>

      <Contactform/>


      <Footer
        newsletterSection={true}
      />
</div>
  )
}

export default Home