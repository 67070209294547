import React from 'react'
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import ContactPageForm from '../component/ContactPageForm/ContactPageForm'
import FaqSection from '../component/FaqSection/FaqSection'
import { Helmet } from 'react-helmet'

const faqData = [
  {
    title:"What is home staging, and why is it important?",
    content:"Home staging is the art of preparing a property for sale by strategically arranging furniture, decor, and accessories to showcase its best features and appeal to potential buyers. It's crucial because it helps create an emotional connection with buyers, allowing them to envision themselves living in the space. Studies have shown that staged homes typically sell faster and for a higher price than unstaged ones, making it a valuable investment for sellers."
  },
  {
    title:"How does the decluttering process work, and why is it necessary before staging a home?",
    content:"Decluttering involves removing excess items and organizing the remaining belongings to create a clean and spacious environment. Before staging a home, decluttering is essential because it allows potential buyers to focus on the property's features rather than being distracted by clutter. It also helps create a sense of openness and allows for better flow throughout the space, enhancing its overall appeal."
  },
  {
    title:"What sets MES Décors apart from other home staging companies?",
    content:"At MES Décors, we take a personalized approach to every project, tailoring our services to meet the unique needs and preferences of each client. Our team of experienced professionals combines creativity with attention to detail to ensure that every home we stage tells a compelling story and leaves a lasting impression on potential buyers. With a focus on quality, professionalism, and customer satisfaction, we strive to exceed expectations and deliver exceptional results every time."
  },
  {
    title:"Do you offer interior design services for homeowners who are not selling their homes?",
    content:"Yes, we do! In addition to our home staging services, we also offer interior design services for homeowners looking to enhance their living spaces. Whether you're renovating, redecorating, or simply seeking inspiration to refresh your home, our talented team is here to help. From concept development to final execution, we'll work closely with you to create a personalized design that reflects your style, needs, and budget."
  },
]

function Contact() {
    return (
        <>
          <Helmet>
            <title> 
            Home Staging & Consultation Services | Contact MES Décors for Staging & Design Canada
            </title>
            <meta 
            name='description' 
            content="Transform your property with MES Décors' expert staging and consultation in Canada. We enhance appeal for buyers, increase sales, and maximize ROI with stylish, functional designs.Contact Us Today" 
            />
<link rel="canonical" href="https://www.mesdecors.ca/contact" />

            </Helmet>
        
            <Header
                darkBg={true}
            />
    
            <ContactPageForm/>

            <FaqSection
               heading="Frequently Asked Questions"
               para="Got questions? We've got answers! Explore our FAQs to learn more about MES Décors and our services."
               subHeading=""
               showImg={false}
               faqData={faqData}
            />

            <div style={{height:"30px"}}></div>
    
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Contact