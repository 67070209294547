import React from 'react'
import "./GetInTouch.css"
import { useNavigate } from 'react-router-dom'

function GetInTouch({
    bgtransparent=false,
    heading="Get in touch today"
}) {
    const navigate = useNavigate()
  return (
    <div className='GetInTouch-container '
        style={!bgtransparent?{background:"black"}:{background:"transparent"}}
    >
        <div className={`${!bgtransparent && "left-gradient-upper"} standard-padding-space`}>
        <div className='container my-5 py-4'>
            <div className='row align-items-center gy-4'>
                <div className='col-lg-4'>
                    <div className='text-lg-start text-center'>
                        <h3 className='body-heading color-light' style={{textTransform:"uppercase"}}>{heading}</h3>
                       
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='text-lg-start text-center text-card'>
                        <p className={`body-paragraph color-light justify-content-lg-start justify-content-center d-flex align-items-center gap-lg-4 gap-3`}><p className=' mb-0' >Milton, Ontario, Ca</p><div style={{height:"0",width:"50px",borderTop:`1px solid white`,margin:"auto 0" }} className='d-none d-lg-block'></div></p>
                        <a href='mailto:inquiry@mesdecors.ca' className='mt-5' style={{textDecoration:"none"}}>
                            <h6 className='sub-heading color-light' style={{fontSize:"20px"}}>inquiry@mesdecors.ca</h6>
                        </a>
                    </div>
                </div>
                <div className='col-lg-4'>
                    <div className='text-lg-start text-center text-card'>
                        
                        <p className={`body-paragraph color-light justify-content-lg-start justify-content-center d-flex align-items-center gap-lg-4 gap-3`}><p className=' mb-0'>CaLL US</p><div style={{height:"0",width:"50px",borderTop:`1px solid white`,margin:"auto 0" }} className='d-none d-lg-block'></div></p>
                        <a href='tel:+1 (437) 998-6650' className='mt-5' style={{textDecoration:"none"}}>
                            <h6 className='sub-heading color-light' style={{fontSize:"20px"}}>+1 (437) 998-6650</h6>
                        </a>
                    </div>
                </div>

                <div className='col-12 text-center text-lg-start'>

                    <button className='genral-btn mt-5 ' onClick={()=>{navigate("/contact")}}>BOOK A CONSULT Now</button>
                </div>
                
            </div>


        </div>
        <div style={{height:"40px"}}></div>
    </div>
    </div>
  )
}

export default GetInTouch