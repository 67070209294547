import React from 'react'
import HeroBannerDark from '../component/Common Banner Component Dark/HeroBannerDark'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import PortfolioSection from '../component/PortfolioSection/PortfolioSection'

function Portfolio() {
    return (
        <>
        
            <HeroBannerDark 
                subHeading="PORTFOLIO"
                Heading="MES "
                spanHeading='Décors GALLERY'
                nextLineSpanHeading=""
                Paragraph=""
                bgImg=""
                btn={false}
                bottomSection={false}
            />


        <div className='bg-black  right-gradient-bottom'>
          <div className='left-gradient-upper'>

            <PortfolioSection/>
    
          
          <GetInTouch
            bgtransparent={true}
          />
          </div>
        </div>
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Portfolio