import React, { useState } from 'react';
import './Popup.css';

function Popup({ closePopup,src }) {


  return (
    <div className='popup'>
      <div className='popup-inner'>
       
        <div className='img-container'> 
          <img src={src} className='img-fluid' alt='icon'/>
        </div>
      </div>
    </div>
  );
}

export default Popup;
