import React from 'react'
import "./BlogInnerHeroBanner.css";
import Header from '../Header/Header';


function BlogInnerHeroBanner({
    heading="Artical",
    date=""
}) {
    
  return (
    <div className='BlogInnerHeroBanner-container '>
        <div className=' h-100 pt-xl-3 pt-0'>
            
        <Header/>
        <div className='container  h-100 d-flex justify-content-center align-items-center '>
            <div className='main py-5 mt-4'>

                <div className='text-content text-center py-4'>
                    <div className='blog-details-container d-flex align-items-center justify-content-center my-1 my-xxl-4'>
                        <p className='body-paragraph mb-0'>DATE</p>
                        <div style={{borderBottom:"1px solid white ",width:"80px"}}></div>
                        <p className='body-paragraph mb-0'>{date}</p>

                    </div>
                    <h1 className='hero-section-heading  color-light  mx-auto my-4'>
                        {heading}
                    </h1>

                    {/* <p className='body-paragraph color-light mt-3'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p> */}
                </div>

                

            </div>
        </div>
        </div>

    </div>
  )
}

export default BlogInnerHeroBanner