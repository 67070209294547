import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import InteriorDesign from '../component/InteriorDesign/InteriorDesign'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import { Helmet } from 'react-helmet'

function Stagging() {
    return (
        <>
         <Helmet>
          <title> 
          Professional Home Staging Services | Best home staging & Interior Design consultation - Canada
          </title>
          <meta 
          name='description' 
          content="Elevate your property's appeal with expert home staging and staging & Interior Design consultation service  in Canada .Tailored designs for homes, new builds, and model homes to attract buyers and boost sales." 
          />
          <link rel="canonical" href="https://www.mesdecors.ca/staging" />


        </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="PROFESSIONAL HOME STAGING SERVICE"
                nextLineSpanHeading=""
                Paragraph="Elevate your property's appeal and maximize its value with our professional staging services. From strategic furniture placement to eye-catching decor, we'll help you make a lasting impression."
                bgImg="/images/creatives/stagging-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
          <InteriorDesign/>
           
          <GetInTouch heading="LET'S START STAGING TODAY!"/>
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default Stagging