import React,{useState,useEffect} from "react";
import "./NewsList.css";
import NewsCard from "./NewsCard";
import LatestNews from "../LatestNews/LatestNews"
import { useNavigate } from "react-router-dom";
import axios from "axios"
import {Spinner} from "react-bootstrap"

const dummyFirstBlog = {
  blog_image: "/images/creatives/blog-thumb-1.jpg",
  title: "28 splendid big kitchen ideas you can follow to create",
  brief_paragraph:"Lorem ipsum dolor sit amet consectetur adipiscing elit lectus dapibus natoque viverra in lectus mauris.",
  date:"",
  slug_url: "inner",
  catagory:"RESOURCES",
}

const dumyBlogs = [
  {
    blog_creative: "/images/creatives/blog-thumb-1.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-2.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-3.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-4.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-5.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
  {
    blog_creative: "/images/creatives/blog-thumb-6.jpg",
    main_heading: "28 splendid big kitchen ideas you can follow to create",
    date:"",
    slug_url: "inner",
    catagory:"RESOURCES",
  },
];



let defaultBlogObj ;

function NewsList() {


  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const[firstBlog,setFirstBlog] = useState()

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          const [first , ...otherBlog] = res.data.data;
          setFirstBlog(first)
          otherBlog.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          otherBlog.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/artical/${slug}`);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentNewsData = blogs.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if(page <= (Math.ceil(blogs.length/itemsPerPage)))
    {
      setCurrentPage(page);
    }
    else{
      return
    }
  }

  return (
    <>
    {loading ? 
        (<div className="w-100 text-center mb-5">
          <Spinner
            style={{ color: "#3a3a3a", width: "120px", height: "120px" }}
          />
        </div>):
        <>
        {firstBlog && 
        <LatestNews
          firstBlog={firstBlog}
          redirectionFunction={redirectUserToBlog}
        />
        }
        <div className={`news-container `}>
          <div className="right-gradient">
          <div className="left-gradient-upper standard-padding-space py-4">
          <div className="container">
            <div className="row my-5">
              <div className="col-lg-5">
                <h3 className="body-heading color-light">Latest Posts</h3>
              </div>
              <div className="col-lg-7">
                <p className="body-paragraph color-light">Stay up-to-date with our newest articles, packed with inspiration and expert advice on home staging, interior design, and decluttering.</p>
              </div>
            </div>

          <div className="row">
            
            {currentNewsData.map((ele,ind) => (
              <div 
                key={ele.id} 
                className={`col-lg-6 mx-sm-auto gy-4 mb-3 box-${ind%2}`}
                // style={currentPage !== 0 ? { animation: "fade-animation 0.5s ease-in-out", animationIterationCount: 1, animationFillMode: "forwards" } : {}}
              >
                <NewsCard 
                 img={ele.blog_creative}
                 title={ele.main_heading}
                 anchor={ele.slug_url}
                 redirectionFunction={redirectUserToBlog}
                 date={ele.date}
                 catagory={ele.catagory}
                />
              </div>
            ))}
          </div>
          <div className="page-btn-container pt-4 my-5 d-flex align-items-center justify-content-center gap-3">
              <button key="next" className="genral-btn" onClick={() => handlePageChange(currentPage + 1)}>
                  NEXT
              </button>
          </div>
        </div>
        </div>
        </div>
      </div>
      </>}
    </>
  );
}

export default NewsList;
