import React, { useState } from "react";
import "../Popup/Popup.css";
import Popup from '../Popup/Popup'
import ContactForm from "../ContactForm/ContactForm";

function AppointmentModal({ setOpenModal,src}) {
  

    

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send data to backend
    
    setOpenModal(false); // Close modal after form submission
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer" onClick={() => setOpenModal(false)}>
        
        
        <div className="body">
        <Popup closePopup={() => setOpenModal(false)} src={src} /> {/* Pass closePopup function */}
        </div>
        
      </div>
    </div>
  );
}

export default AppointmentModal;
