import React from 'react'
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import QuoteForm from '../component/QuoteForm/QuoteForm'
import { Helmet } from 'react-helmet'

function GetAQoute() {
    return (
        <>
          <Helmet>
            <title> 
            Request a Quote | Contact MES Décors for Staging & Design Canada
            </title>
            <meta 
            name='description' 
            content="Get a quote for expert home staging and design services from MES Décors. Provide your property address and contact information to start transforming your space." 
            />
<link rel="canonical" href="https://www.mesdecors.ca/get-a-quote" />
            </Helmet>
        
            <Header
                darkBg={true}
            />
    
            <QuoteForm/>
            
            <div style={{height:"30px"}}></div>
    
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default GetAQoute