import React from 'react'
import NewsList from "../component/NewsList/NewsList"
import HeroBannerDark from '../component/Common Banner Component Dark/HeroBannerDark'
import Footer from '../component/Footer/Footer'

function News() {
  return (
    <>
     <HeroBannerDark 
          subHeading=""
          Heading="Our Blogs"
          nextLineSpanHeading=""
          Paragraph="Discover the latest trends, tips, and insights in home staging, interior design, and decluttering on our blog."
          bgImg=""
          btn={false}
          bottomSection={false}
          paddingBottom={false}
      />
    <NewsList/>

    <Footer/>
  
    </>
  )
}

export default News