import React from 'react'
import "./ProjectGlance.css"
import { useNavigate } from 'react-router-dom'

function ProjectGlance() {
    const navigate = useNavigate()
    return (
        <div className='ProjectGlance-container  '>
        <div className="right-gradient-bottom ">
        <div className="left-gradient-upper standard-padding-space">
            <div className='container my-4 my-xl-4 py-xl-3 py-3'>
                <div className='text-sec text-center text-lg-start'>
    
                            <h6 className='sub-heading color-light d-flex justify-content-center justify-content-lg-start gap-3 mb-4 '><div style={{height:"0",width:"50px",borderBottom:`1px solid white`,margin:"auto 0" }}></div>OUR GALLERY</h6>
                    <div className='row'>
                        <div className='heading-sec col-lg-6'>
                            <h3 className='body-heading color-light mb-4'>A QUICK GLANCE AT SOME OF OUR WORK</h3>
                        </div>
                        <div className='heading-sec col-lg-6'>
                            <p className='body-paragraph color-light'>Explore our gallery to witness the breathtaking transformations we've accomplished. Our staging and design work brings out the beauty and potential of every property, whether it's a cozy home or a sleek commercial space. Find inspiration from our previous projects and envision the possibilities for your own space</p>
                        </div>
    
                    </div>
                    <button 
                        className='genral-btn text-start mt-3'
                        onClick={()=>{navigate("/portfolio")}}
                    >OUR GALLERY</button>   
    
                </div>
                <div className='img-sec my-5'>
                    <div className='d-flex flex-column flex-lg-row img-main gap-4 w-100'>
                        <div className='d-flex img-main-1 flex-column  gap-4 w-100'>
                           
                           
                            {/* <div 
                                className='gal-img-1'
                                style={{backgroundImage:`url('/images/creatives/gallery-img-1.jpg')`,backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                            >
                            </div> */}
                            <img src='/images/creatives/gallery-img-1.jpg' className='gal-img-1' alt='gallery' loading='lazy'/>

                            
                            {/* <div 
                                className='gal-img-3'
                                style={{backgroundImage:`url('/images/creatives/gallery-img-3.jpg')`,backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                            >
                            </div> */}
                             <img src='/images/creatives/gallery-img-3.jpg' className='gal-img-3' alt='gallery' loading='lazy'/>

                        </div>
                        <div className='d-flex img-main-2 flex-column gap-4 w-100'>
                            {/* <div 
                                className='gal-img-2'
                                style={{backgroundImage:`url('/images/creatives/gallery-img-2.jpg')`,backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                            >
                            </div> */}
                            <img src='/images/creatives/gallery-img-2.jpg' className='gal-img-2' alt='gallery' loading='lazy'/>
                            {/* <div 
                                className='gal-img-4'
                                style={{backgroundImage:`url('/images/creatives/gallery-img-4.jpg')`,backgroundSize:"cover",backgroundRepeat:"no-repeat"}}
                            >
                            </div> */}
                            <img src='/images/creatives/gallery-img-4.jpg' className='gal-img-4' alt='gallery' loading='lazy'/>
                           
                        </div>

                    </div>
                </div>    
            </div>
    
        </div>
        </div>
        </div>
      )
}

export default ProjectGlance