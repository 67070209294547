import React, { useState,useRef,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import "./Header.css";

const Header = ({darkBg=false}) => {
  const [menuState, setMenuState] = useState(false);
  const [changeArrowcolor, setChangeArrowColor] = useState(false);
  const [changeArrowcolorDec, setChangeArrowColorDec] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [isDropdownOpenDec, setIsDropdownOpenDec] = useState(false);
  const [isDropdownOpenDeskDec, setIsDropdownOpenDeskDec] = useState(false);
  const navigate = useNavigate()
  const clickCountRef = useRef(0);
  const dropdownRef = useRef();
  const dropdownRefDec = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
      if (dropdownRefDec.current && !dropdownRefDec.current.contains(event.target) && isDropdownOpenDeskDec) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDeskDec(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk,isDropdownOpenDeskDec]);


const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/staging");
        clickCountRef.current = 0;
      }
  };
const onClickDec = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpenDec(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpenDec(false)
        navigate("/decorating");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <div className={`header w-100`}
      style={darkBg ? {backgroundColor:"transparent"} : {}}
      
    >
      <div 
        className="header-wrapper container"
      >
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container px-xl-0 px-2 py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center px-xl-2" >
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src={`/images/icons/nav-logo${darkBg ? "-b" : ""}.png`} className="nav-logo" onClick={()=>{navigate("/")}} style={{cursor:"pointer"}} alt='icon'/>
              <nav>
                <ul
                  className="nav-list d-xl-flex gap-xl-4 gap-lg-3 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      ABOUT
                    </Link>
                  </li>

                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true);setIsDropdownOpenDeskDec(false)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColor(true)}} onMouseOut={()=>{setChangeArrowColor(false)}}>
                          <Link to={"/staging"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDesk(false)}} style={{color:`${changeArrowcolor ? "#d0d0d0":"white"}`}}>STAGING</Link>
                          <img src={`/images/icons/drop-icon-${darkBg ? "b":"w"}.png`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } alt='icon'/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <Link to={"/staging-home-owner"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">HOME OWNER</p>
                        </Link>
                        <Link to={"/staging-realtors"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">REALTORS</p>
                        </Link>
                        <Link to={"/staging-builders"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">BUILDERS</p>
                        </Link>
                        <Link to={"/decorating-organizing"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDeskDec(false)}}>
                          <p className="mb-0">ADDITIONAL SERVICES</p>
                        </Link>
                      </div>
                    </li>
                    </div>
                  <div className="navigation-dropdown" ref={dropdownRefDec}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDeskDec(true);setIsDropdownOpenDesk(false)}} >

                      <div className="dropdown-trigger d-flex align-items-center" onMouseOver={()=>{setChangeArrowColorDec(true)}} onMouseOut={()=>{setChangeArrowColorDec(false)}}>
                          <Link to={"/decorating"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDeskDec(false)}} style={{color:`${changeArrowcolorDec ? "#d0d0d0":"white"}`}}>DECORATING</Link>
                          <img src={`/images/icons/drop-icon-${darkBg ? "b":"w"}.png`} className={isDropdownOpenDeskDec ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } alt='icon'/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDeskDec ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDeskDec(true)}} onMouseLeave={()=>{setIsDropdownOpenDeskDec(false)}} style={{left:"0px"}}>
              
                        <Link to={"/commercial"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDeskDec(false)}}>
                          <p className="mb-0">COMMERCIAL</p>
                        </Link>
                        <Link to={"/decorating-home-owner"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDeskDec(false)}}>
                          <p className="mb-0">RESIDENTIAL</p>
                        </Link>
                        <Link to={"/decorating-short-term-rental"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDeskDec(false)}}>
                          <p className="mb-0">SHORT TERM RENTAL</p>
                        </Link>
                      </div>
                    </li>
                    </div>

                  <li>
                    <Link to={"/get-a-quote"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                    REQUEST A QUOTE
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contact"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                      CONTACT
                    </Link>
                  </li>
                </ul>
              </nav>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-xl-none d-inline"
              >
                <img
                  src={`/images/icons/hamburger-menu${darkBg ? "-b":""}.png`}
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

              <div className="nav-buttton ml-5 d-xl-flex d-none gap-3 extras-btns">
                {/* <a href='tel:905-841-9879' style={{textDecoration:"none"}}></a> */}
                  <button 
                  className={`${darkBg ? " genral-btn-light":"hero-genral-btn"}  font-lato`}
                  onClick={()=>{navigate("/contact")}} 
                  >
                    GET IN TOUCH
                  </button>
              </div>
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100">
            <div className="py-3 d-xl-none" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list d-xl-none w-100 gap-4 hs-dropdown [--trigger:hover] pl-4"
              style={{ display: menuState ? "block" : "none" }}
            >
              <li>
                <Link to={"/"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                HOME
                </Link>
              </li>
              <li>
                <Link to={"/about"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                ABOUT
                </Link>
              </li>
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                      <a className={`nav-links font-lato ${darkBg && "color-dark"}`} >STAGING</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpen ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } alt='icon'/>
                  </div>
                  <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    <Link to={"/staging-home-owner"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">HOME OWNER</p>
                    </Link>
                    <Link to={"/staging-realtors"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">REALTORS</p>
                    </Link>
                    <Link to={"/staging-builders"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                      <p className="mb-0">BUILDERS</p>
                    </Link>
                   
                    <Link to={"/decorating-organizing"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDec(false); setMenuState(false)}}>
                      <p className="mb-0">ADDITIONAL SERVICES</p>
                    </Link>
                    
                  </div>
                </div>
                </li>
               <li>
                <div className="navigation-dropdown">
                  <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickDec} >
                      <a className={`nav-links font-lato ${darkBg && "color-dark"}`} >DECORATING</a>
                      <img src='/images/icons/drop-icon-b.png' className={isDropdownOpenDec ? "rotate-icon arrow-icon":"rotate-back arrow-icon" } alt='icon'/>
                  </div>
                  <div className={`dropdown-content ${isDropdownOpenDec ? 'open' : 'd-none'}`} style={{top:"85%",left:"0%"}}>
                    
                    
                    <Link to={"/commercial"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDec(false); setMenuState(false)}}>
                      <p className="mb-0">COMMERCIAL</p>
                    </Link>
                    <Link to={"/decorating-home-owner"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDec(false); setMenuState(false)}}>
                      <p className="mb-0">RESIDENTIAL</p>
                    </Link>
                    <Link to={"/decorating-short-term-rental"} className={`nav-links font-lato ${darkBg && "color-dark"}`} onClick={()=>{setIsDropdownOpenDec(false); setMenuState(false)}}>
                      <p className="mb-0">SHORT TERM RENTAL</p>
                    </Link>
                    
                  </div>
                </div>
                </li>
              
              <li>
                <Link to={"/get-a-quote"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                REQUEST A QUOTE
                </Link>
              </li>
              <li>
                <Link to={"/contact"} className={`nav-links font-lato ${darkBg && "color-dark"}`}>
                CONTACT
                </Link>
              </li>
            </ul>
            <div className="nav-buttton flex-wrap d-flex gap-4 extras-btns">
              {/* <a href='tel:905-841-9879' style={{textDecoration:"none"}}></a> */}
                <button 
                className="genral-btn-light font-lato" 
                onClick={()=>{navigate("/contact")}} 
                >
                   GET IN TOUCH
                </button>
            </div>
            </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 