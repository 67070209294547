import "./Testimonials.css"
import React,{useState} from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,Pagination  } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import TestimonialCard from "./TestimonialCard"

const testimonialData = [
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"John DeSouza",
    rank:"Real Estate Photographer",
    clientReview:"In the world of real estate selling, presentation is paramount, and when it comes to apartments, the art of home staging can make all the difference in attracting potential buyers or renters. Recently, I as a real estate photographer, had the pleasure of experiencing firsthand the transformative effects of professional home staging services in an apartment, and I must say, the results were nothing short of impressive. This made my life very easy to capture the essence of the apartment. The staging gave life to the rooms, else I would have been just taking photos of the empty rooms with barren walls. The attention to detail was evident from the moment I stepped in. Through strategic placement and thoughtful design choices, the staging team was able to create a sense of flow and balance that truly elevated the space in this small apartment. The result was a space that felt not only visually appealing but also lived-in and inviting – a place where prospective buyers could envision themselves making a home. In conclusion, after having photographed apartments and houses for so many years, I can truly recommend this home staging service. The expertise and attention to detail provided by the staging team were truly exceptional, and the impact on the apartment's marketability was nothing short of remarkable."
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Vanessa Pereira",
    rank:"Realtor",
    clientReview:"I recently worked with Mirna and Louay from MES Décors to stage two properties. They are just amazing with their ideas and are very detail oriented. They go above and beyond. Mirna with her interior design background and Louay taking care of the smallest details, together make an awesome team. The sellers were thrilled to see their homes when they were listed. I highly recommend their services and I can assure you that you will be presently surprised."
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Matthew",
    rank:"Events Photographer",
    clientReview:'If you more value and a greater selling price for your home look no further! MES Decor is professional and knowledgeable  and brings style to your homes staging. Give  it that "Wow" factor.  Let them help you attract the perfect buyer and the sale and price that you want to your home.  From start to finish their team will consult and take care of everything. Highly recommended!'
  },
  {
    clientImg:"/images/icons/testimonial-male.png",
    clientName:"Eliie Maidaa",
    rank:"",
    clientReview:'I have worked with Louay on decorating my town house in Whitby. My wife and I were very satified with their service and attention to details. Strongly recomended'
  },

] 

function Testimonials() {



  return (
    <div className="Testimonials-container py-5">
      <div className="container w-100 container-fluid py-4">
    <div className=" d-flex align-items-center w-100">
      <div className="col-12 text-center px-0">
        <p className="sub-heading mb-2 color-dark" >Testimonials </p>
        <h3 className="body-heading color-dark mt-4">WHAT OUR VALUABLE CLIENTS SAY</h3>
        

        <div className="card-list" style={{position:"relative"}}>

        <Swiper
        spaceBetween={0}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}
        autoplay={
          {
            delay:2000,
            disableOnInteraction:false
          }
        }
        modules={[Autoplay,Pagination]}
        pagination={{ clickable: true }}
        observer ={true}
        observeParents={true}
        parallax={true}
        breakpoints={{
            280: {
              slidesPerView: 1,
            },
            1450: {
              slidesPerView: 3,
            }
          }}
        className="testimonials-list py-5 mt-2"
        id="testimonial-cards-slider"
            >
              {
                testimonialData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind} className="pt-5 px-3 px-sm-0">
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview}/>
                    </SwiperSlide>
                    
                     </>)
                })
              }
        </Swiper>
        
        </div>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials