import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import GetInTouch from '../component/GetInTouch/GetInTouch'
import TextContent from '../component/TextContent/TextContent'
import OccupiedStagging from '../component/OccupiedStagging/OccupiedStagging'
import { Helmet } from 'react-helmet'

function HomeOwner() {
    return (
        <>
         <Helmet>
          <title> 
          Expert Home Staging Services For Homeowners
          </title>
          <meta 
          name='description' 
          content="Transform your home with MES Décors’ home staging & Interior Design  services in Canada. Highlight key features and create a warm atmosphere to attract buyers and sell faster at higher prices." 
          />
          <link rel="canonical" href="https://www.mesdecors.ca/staging-home-owner" />

        </Helmet>
        
            <HeroBanner 
                subHeading=""
                Heading="STAGING FOR HOMEOWNERS"
                nextLineSpanHeading=""
                Paragraph="Make your home irresistible to buyers with our expert staging services. At MES Décors, we transform your living spaces to highlight their best features and create a warm, inviting atmosphere."
                bgImg="/images/creatives/home-owner-hero-bg.jpg"
                btn={false}
                sideLinks={false}
            />
    
            <TextContent
                subHeading=""
                heading= "DOES HOME STAGING REALLY WORK?"
                content={`Absolutely! Home staging can make a significant difference in how quickly and for how much your property sells. By decluttering, organizing, and styling your home, staging helps potential buyers visualize themselves living in the space. Studies show that staged homes often sell faster and at higher prices compared to non-staged homes. At MES Décors, our staging expertise ensures your home stands out in the market, creating a lasting impression on buyers.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={false}
                img="/images/creatives/home-owner-text-1.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />

            <OccupiedStagging
              heading="OCCUPIED STAGING"
              content="Transforming your home for sale is easy with our streamlined process. Here's how MES Décors makes it happen:"
              card1={{
                title:"Consultation",
                content:"We start with a thorough consultation to understand your home's unique features and your goals. This helps us create a tailored staging plan.\n This step involves creating personalized recommendations for preparing the property for sale, uncovering areas leaking equity and providing solutions to prevent them. We use a checklist that the homeowner will receive after the consultation is complete. It will include all the important tasks, such as packing away personal and non-essential items, cleaning, painting, repairing, replacing, and renewing to make the property market-ready. This step covers everything from improving the curb appeal to enhancing all the rooms indoors. It is an incredibly valuable and important step as it helps homeowners understand the value of their property and, most importantly, eliminates the overwhelming feeling of getting their home ready by providing a step-by-step task checklist for satisfaction."
              }}
              card2={{
                title:"The work",
                content:"This step involves completing the tasks and items identified during the consultation. The homeowner can save money by doing the work themselves or by having our team help them declutter and organize their space. This helps in removing any distractions and highlighting the best aspects of the space. If we can’t do it, we know who does."
              }}
              card3={{
                title:"Showcasing",
                content:"After finalizing the property recommendations, our team showcases each room to optimize flow, size, and features by carefully arranging furniture, accessories, lighting, and artwork. We use stylish furniture and decor to create an inviting atmosphere that appeals to potential buyers, ensuring that your home looks its best."
              }}
            />

            <OccupiedStagging
              heading="VACANT STAGING"
              content="Vacant homes can often feel cold and uninviting, making it hard for potential buyers to envision their lives there. At MES Décors, we specialize in the art of vacant staging, transforming empty spaces into warm, welcoming environments."
              card1={{
                title:"Consultation",
                content:"It's essential to visit the property to assess the current situation and determine the necessary steps. As a homeowner, you might feel overwhelmed by the amount of work required to revitalize a vacant home. Our team will work their magic and assist you in creating a space that will appeal to potential buyers while staying within your budget. All recommendations are aimed at presenting the property in the best light possible and maximizing its equity."
              }}
              card2={{
                title:"The work",
                content:"Whether the homeowner decides to do the work themselves or hire external contractors, our services are available to manage the execution of our proposed recommendations. Our team carefully selects and arranges furniture, artwork, and accessories to highlight each room's potential and charm. By creating a lived-in feel, we help buyers connect emotionally with the space, increasing the likelihood of a quick and profitable sale. Let us turn your vacant property into a buyer's dream home"
              }}
              card3={{
                title:"Showcasing",
                content:"In vacant homes, our staging process is similar to occupied home staging. We help homeowners identify necessary upgrades through our consultation process, uncovering areas leaking equity and providing solutions to prevent them. Once recommendations are made, we complete the showcasing using furniture and accessories that we provide, creating the buyer's dream home."
              }}
            />
            <TextContent
                subHeading=""
                heading= "THE ART OF STAGING"
                content={`Vacant homes can often feel cold and uninviting, making it hard for potential buyers to envision their lives there. At MES Décors, we specialize in the art of vacant staging, transforming empty spaces into warm, welcoming environments.\n Our team carefully selects and arranges furniture, artwork, and accessories to highlight each room's potential and charm. By creating a lived-in feel, we help buyers connect emotionally with the space, increasing the likelihood of a quick and profitable sale. Let us turn your vacant property into a buyer's dream home.`}
                btn={true}
                btnText="Get A QUOTE"
                btnRef='/get-a-quote'
                btnRefTel={false}
                shiftRow={true}
                img="/images/creatives/home-owner-text-2.jpg"
                reviewContent={false}
                btnImg={false}
                fontDark={true}
            />
           
          <GetInTouch
            heading='Let’s Start Staging Today!'
          />
          <Footer
            newsletterSection={true}
          />
        </>
      )
}

export default HomeOwner