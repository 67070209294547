import React from 'react'
import "./DecoratingInteriorDesign.css"
import { useNavigate } from 'react-router-dom'

function ImgCard({
    title="",
    content="",
    img="",
    width="50",
    anchor="/"
})
{
    const navigate = useNavigate()
    return(
        <div 
        className='img-container w-100 d-flex flex-column align-items-start justify-content-end p-xl-5 p-4'
        style={{backgroundImage:`url(${img})`}}
        onClick={()=>{navigate(anchor)}}
    >
        <h3 className='body-heading color-light' style={{textTransform:"uppercase"}}>{title}</h3>
        <p className={`body-paragraph color-light w-${width}`}>{content}</p>
    </div>
    )
}

function DecoratingInteriorDesign({
    heading="A BROAD SET OF INTERIOR DESIGN",
    content="MES Décors is a specialized home and commercial staging company that concentrates on creating visually appealing displays and environments for real estate. Our aim is to improve the value and appeal of properties, either for a more contemporary lifestyle or to increase equity. Our ultimate goal is to convert any space into an exquisite and unforgettable setting that enhances the overall guest experience."
}) {
  return (
    <div className='DecoratingInteriorDesign-container standard-padding-space standard-margin-space'>
        <div className='container'>
            <div className='text-sec'>

                <div className='row'>
                    <div className='heading-sec col-lg-6'>
                        <h3 className='body-heading color-dark'>{heading}</h3>
                    </div>
                    <div className='heading-sec col-lg-6'>
                        <p className='body-paragraph color-dark'>{content}</p>
                    </div>

                </div>
                

            </div>
            <div className='img-sec my-4'>
                
                    <div className='mt-4'>
                    <ImgCard
                        title="Commercial Decorating"
                        content="Transform your business environment with MES Décors' professional Commercial Decorating Services. Our expert team will help you create a new space or enhance your existing one boosting your employees’ productivity and your customers’ satisfaction, hence, increasing revenue."
                        img='/images/creatives/did-card-bg-1.jpg'
                        width='50'
                        anchor='/commercial'
                    />
                    </div>
                    <div className='mt-4'>
                        <ImgCard
                            title="Short Term Rentals"
                            content='Transform your short-term rental into a memorable getaway with our expert design services.'
                            img='/images/creatives/did-card-bg-2.jpg'
                            width='50'
                            anchor='/decorating-short-term-rental'
                        />
                    </div>

                    <div className='mt-4'>
                        <ImgCard
                            title="Residential Decorating"
                            content='Simplify your space, find peace of mind, and experience order with MES Décors.'
                            img='/images/creatives/did-card-bg-3.jpg'
                            width='50'
                            anchor='/decorating-home-owner'
                        />
                    </div>
            </div>             
        </div>

    </div>
  )
}

export default DecoratingInteriorDesign