import React from 'react'
import "./DecoratingInnerMidContent.css"
import { useNavigate } from 'react-router-dom'

function DecoratingInnerMidContent({
    heading="A broad set of interior design",
    content="MES Décors is a specialized home and commercial staging company that concentrates on creating visually appealing displays and environments for real estate. Our aim is to improve the value and appeal of properties, either for a more contemporary lifestyle or to increase equity. Our ultimate goal is to convert any space into an exquisite and unforgettable setting that enhances the overall guest experience.",
    img1="",
    img2=""
}) {
    const navigate = useNavigate()
  return (
    <div className='DecoratingInnerMidContent-container '>
        <div className='right-gradient-bottom '>
        <div className='left-gradient-upper standard-padding-space'>

        <div className='container my-4 py-4'>
            <div className='text-sec text-center text-lg-start'>
                <div className='row'>
                    <div className='heading-sec col-lg-6'>
                        <h3 className='body-heading color-light'>{heading}</h3>
                        <button className='genral-btn text-start mt-4 mt-lg-5' onClick={()=>{navigate("/contact")}}>CONTACT US</button>   
                    </div>
                    <div className='heading-sec col-lg-6'>
                        <p className='body-paragraph color-light'>{content}</p>
                    </div>

                </div>
                

            </div>

            <div className='img-sec mt-4 mt-lg-0'>
                <div className='d-flex flex-column flex-lg-row align-items-end justify-content-between gap-4'>
                    <div style={{backgroundImage:`url("${img1}")`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center",height:"400px"}} alt='content ' className='w-100 '/>
                    <div style={{backgroundImage:`url("${img2}")`,backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center",height:"500px"}} alt='content ' className='w-100 '/>
                    
                </div>

            </div>
            
        </div>
    </div>
    </div>
    </div>
  )
}

export default DecoratingInnerMidContent